
























































































































































































































import { Watch, Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.css";

// 导入可用的插件
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/help";
import "tinymce/plugins/wordcount";

import InputComonentVue from "@/components/BasicConponents/InputConponentsVue.vue";
import { Column } from "@/common/Views/system/column/column";

import { Commodity } from "@/common/Views/BeefOrdering/CommodityClassification/CommodityClassification";
import { productList } from "@/common/Views/BeefOrdering/productList/productList";
@Component({
    components: {
        InputComonentVue,
        editor: Editor,
        PageBox,
    },
})
export default class AddClassification extends Vue {
    private form: any = {};
    private skuDialogVisible = false;
    private skuList = [];
    private kgList = ["5kg", "10kg", "15kg", "25kg"];
    private lList = ["500ml", "1L", "2L", "4L"];
    private updateType = false;

    private tablePage = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private isProduct = 1;
    private switchTab(type: number) {
        this.isProduct = type;
    }
    private kgSetList = [];
    private lSetList = [];
    private CheckedType = false;

    private handleCheckedChange(type: any) {
        if (type == "kg") {
            this.lSetList = [];
            this.CheckedType = false;
        } else {
            this.kgSetList = [];
            this.CheckedType = true;
        }
    }
    private cancelBtn() {
        this.lSetList = [];
        this.kgSetList = [];
        this.skuDialogVisible = false;
    }
    private skuExample = {
        specsCode: "",
        specs: 0,
        unit: "",
        price: 0,
        discountedPrice: 0,
        promotionalPrice: 0,
        sort: 0,
    };
    private confirmBtn() {
        this.form.skuList = [];
        this.skuDialogVisible = false;
        if (!this.CheckedType) {
            // 遍历 kgSetList 并处理添加操作
            this.kgSetList.forEach((item) => {
                const skuItem = this.splitNumberAndUnit(item);
                const specsUnitString = `${skuItem.specs}${skuItem.unit}`;

                // 在 backupSkuList 中查找相同的 specs 和 unit
                const matchingItem = this.backupSkuList.find(
                    (backupSku) =>
                        `${backupSku.specs}${backupSku.unit}` ===
                        specsUnitString
                );

                if (matchingItem) {
                    // 如果存在匹配项，则将其推入 skuList
                    this.form.skuList.push(matchingItem);
                } else {
                    // 如果不存在匹配项，则创建新的 sku 并添加到 skuList
                    let newSku = { ...this.skuExample };
                    newSku.specs = skuItem.specs;
                    newSku.unit = skuItem.unit;
                    this.form.skuList.push(newSku);
                }
            });

            // 遍历 this.form.skuList 并删除不在 kgSetList 中的项
            this.form.skuList = this.form.skuList.filter((sku) => {
                const specsUnitString = `${sku.specs}${sku.unit}`;
                return this.kgSetList.some((item) => {
                    const skuItem = this.splitNumberAndUnit(item);
                    return (
                        `${skuItem.specs}${skuItem.unit}` === specsUnitString
                    );
                });
            });
        } else {
            // 遍历 kgSetList 并处理添加操作
            this.lSetList.forEach((item) => {
                const skuItem = this.splitNumberAndUnit(item);
                const specsUnitString = `${skuItem.specs}${skuItem.unit}`;

                // 在 backupSkuList 中查找相同的 specs 和 unit
                const matchingItem = this.backupSkuList.find(
                    (backupSku) =>
                        `${backupSku.specs}${backupSku.unit}` ===
                        specsUnitString
                );

                if (matchingItem) {
                    // 如果存在匹配项，则将其推入 skuList
                    this.form.skuList.push(matchingItem);
                } else {
                    // 如果不存在匹配项，则创建新的 sku 并添加到 skuList
                    let newSku = { ...this.skuExample };
                    newSku.specs = skuItem.specs;
                    newSku.unit = skuItem.unit;
                    this.form.skuList.push(newSku);
                }
            });

            // 遍历 this.form.skuList 并删除不在 kgSetList 中的项
            this.form.skuList = this.form.lSetList.filter((sku) => {
                const specsUnitString = `${sku.specs}${sku.unit}`;
                return this.kgSetList.some((item) => {
                    const skuItem = this.splitNumberAndUnit(item);
                    return (
                        `${skuItem.specs}${skuItem.unit}` === specsUnitString
                    );
                });
            });
        }
        this.form.skuList.sort((a: any, b: any) => a.specs - b.specs);
    }
    private updateInput(type: any, row: any, index: any) {}
    private splitNumberAndUnit(str: any) {
        // 正则表达式匹配数字和单位
        const regex = /^(\d+(?:\.\d+)?)\s*(\w+)$/;

        // 使用正则表达式匹配字符串
        const match = str.match(regex);

        if (match) {
            // 如果匹配成功，返回一个包含数字和单位的对象
            return {
                specs: parseFloat(match[1]),
                unit: match[2],
            };
        } else {
            // 如果匹配失败，返回null或者你想要的其他值
            return null;
        }
    }
    private setspecification() {
        this.skuDialogVisible = true;
    }
    private handleInput(row: any, field: any) {
        // 只允许输入数字和小数点，并且限制长度在20个字符以内
        let value = row[field];

        if (field == "sort") {
            // 只允许输入数字，不允许小数点
            value = value.replace(/[^0-9]/g, "");
        } else {
            // 允许输入数字和一个小数点
            value = value.replace(/[^0-9.]/g, "");
            // 确保只有一个小数点
            if (value.split(".").length > 2) {
                value = value.replace(/\.+$/, "");
            }
        }

        // 限制长度为20个字符
        if (value.length > 10) {
            value = value.slice(0, 10);
        }

        row[field] = value;
    }
    //事件

    activated() {
        let id: any = this.$route.query.id;
        this.imgList = []
        this.getListTree();
        this.getBrandList();
        if (id) {
            this.form.id = id;
            this.getProductInfo(this.form.id);
        }
    }
    private uidddd = 0;
    private generateUniqueId() {
        this.uidddd++;
        return `${Date.now()}_${this.uidddd}`;
    }
    private status = false;
    private imgExample = {
        name: "",
        url: "",
        uid: "",
    };
    private backupSkuList = [];
    private getProductInfo(id: any) {
        new productList().getGoodsInfo(id, (res: any) => {
            console.log(res.data, 326);
            let data = res.data;
            data.skuList.forEach((item: any) => {
                if (item.price) {
                    item.price = (item.price / 100).toFixed(2);
                }
                if (item.discountedPrice) {
                    item.discountedPrice = (item.discountedPrice / 100).toFixed(
                        2
                    );
                }
                if (item.promotionalPrice) {
                    item.price = (item.price / 100).toFixed(2);
                }
            });
            this.form = { ...data };
            console.log(this.form);
            data.mainImgs.split(",").forEach((item: any) => {
                this.imgList.push(item);
            });
            this.videoList[0] = {
                name: "video.mp4",
                url: data.video,
                uid: this.generateUniqueId(),
                status: "success",
            };
            this.videoList = [...this.videoList];
            console.log(this.imgList, "this.imgList");
            console.log(this.videoList, "this.videoList");
            this.isProduct = 1;
            this.backupSkuList = [...data.skuList];
            if (data.skuList[0].unit == "kg") {
                data.skuList.forEach((item: any) => {
                    this.kgSetList.push(item.specs + item.unit);
                });
            } else {
                data.skuList.forEach((item: any) => {
                    this.lSetList.push(item.specs + item.unit);
                });
            }
            this.treeSelect = [data.categoryOneId, data.categoryTwoId];
            tinymce.activeEditor.setContent(data.description);
        });
    }
    private changestatusBoolean() {
        if (this.status == true) {
            this.form.status = 0;
        } else {
            this.form.status = 1;
        }
    }

    private dialogImageUrl = "";
    private imgDialogVisible = false;
    private file: any = {};
    private fileList: any = [];
    private imgList: any = [];

    private mounted() {
        tinymce.init({});
    }
    private next() {
        this.isProduct = 2;
    }
    private handleRemove(e: any) {
        const that = this;
        if (that.file.raw.type == "video/mp4") {
            that.videoList = [];
        } else {
            this.imgList = this.imgList.filter(
                (item: any) => item.uid !== e.uid
            );
        }
    }

    private handlePictureCardPreview(file: any) {
        this.dialogImageUrl = file.url;
        this.imgDialogVisible = true;
    }
    private beforeVideoUpload(file: any) {
        console.log(file, 310);
        const videoMaxSize = 2 * 1024 * 1024;
    }
    private handleSuccess(file: any) {
        console.log(file, 301);
        const imgMaxSize = 2 * 1024 * 1024;
        const videoMaxSize = 2 * 1024 * 1024;
        if (file.raw.type == "video/mp4") {
            if (file.raw.size > videoMaxSize) {
                this.$message({
                    type: "warning",
                    message: "视频大小不能超过10M",
                });
                return;
            } else {
                this.file = file;
                this.getbase64();
            }
        } else {
            if (file.raw.size > imgMaxSize) {
                this.$message({
                    type: "warning",
                    message: "图片大小不能超过2M",
                });
                return;
            } else {
                this.file = file;
                this.getbase64();
            }
        }
    }
    private videoList = [];
    private imgRawList = [];
    private videoRawList = [];
    private getbase64(): void {
        const that = this;
        const reader = new FileReader();
        let data = reader.readAsDataURL(that.file.raw);
        reader.onloadend = () => {
            const strBase64 = (<string>reader.result).substring(0);
            if (that.file.raw.type != "video/mp4") {
                console.log("img");
                new Column().uploadImage((res: any) => {
                    let imgObj = { ...that.imgExample };
                    imgObj.name = "mainImgs";
                    imgObj.url = res.data.url;
                    imgObj.uid = that.generateUniqueId();
                    that.imgList.push(imgObj);
                }, strBase64);
            } else {
                console.log("video/mp4");

                new Column().uploadFile(
                    "ordering",
                    (res: any) => {
                        that.videoList[0] = {
                            name: "video",
                            url: res.message,
                            uid: this.generateUniqueId(),
                        };
                        console.log(that.videoList);
                    },
                    that.file.raw
                );
            }
        };
    }
    private handleVideoSuccess(res: any) {
        console.log(res, 366);
        this.form.video = res.message;
    }
    private exceed(res: any) {
        this.$message({
            type: "warning",
            message: "图片上传到达限制，请删除后再进行添加",
        });
    }
    private videoexceed(res: any) {
        this.$message({
            type: "warning",
            message: "视频上传到达限制，请删除后再进行添加",
        });
    }
    //获取所有商品分类列表
    private TreeOption = [];
    private treeSelect = [];
    private getListTree() {
        new Commodity().getListTree((res: any) => {
            this.TreeOption = res.data;
            console.log(this.TreeOption, 282);
            if (this.form.categoryOneId && this.form.categoryTwoId) {
                this.treeSelect = [
                    this.form.categoryOneId,
                    this.form.categoryTwoId,
                ];
            }
        });
    }
    private BrandList = [];
    private getBrandList() {
        new productList().getBrandList((res: any) => {
            this.BrandList = res.data;
            console.log(this.BrandList, 326);
        });
    }
    private handleChange(e: any) {
        console.log(e);
        if (e[0]) {
            this.form.categoryOneId = e[0];
        }
        if (e[1]) {
            this.form.categoryTwoId = e[1];
        }
        console.log(this.treeSelect);
    }
    private plugins =
        "lists link anchor code wordcount image table  searchreplace preview  media insertdatetime fullscreen autolink advlist";
    private toolbar = [
        "undo redo removeformat | bold italic underline strikethrough superscript subscript backcolor forecolor | numlist bullist | blocks | searchreplace fullscreen table",
        "fontfamily fontsize fontsizeselect fontsizeinput | alignleft aligncenter alignright alignjustify lineheight outdent indent | link unlink image | preview code",
    ];

    private init = {
        language_url: "/tinymce/langs/zh_CN.js", // 这里需要单独处理
        skin_url: "/tinymce/skins/ui/oxide",
        content_css: "/tinymce/skins/content/default/content.css",
        language: "zh_CN",
        height: 800,
        width: 1000,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: true,
        menubar: true,
        fontsize_formats:
            "12px 14px 16px 18px 20px 22px 24px 26px 36px 48px 56px",
        font_size_input_default_unit: "px",
        //此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        //如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo: any, success: any, failure: any) => {
            console.log(blobInfo);
            const img = "data:image/jpeg;base64," + blobInfo.base64();
            new Column().uploadImage((res: any) => {
                console.log(res.data.url);
                success(res.data.url);
            }, img);
        },
        resize: true,
        promotion: false,
    };
    private toDelete(row: any, index: any) {
        this.form.skuList.splice(index, 1);
        console.log(this.form.skuList);
    }
}
